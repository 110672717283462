import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import heroImage from '../assets/images/fond3.jpg';
import webdesignImage from '../assets/images/webdesign.png';
import identityImage from '../assets/images/charte.png';
import printImage from '../assets/images/print.png';
import seoImage from '../assets/images/seo.png';
import logoImage from '../assets/images/logodesign.png';
import refonteImage from '../assets/images/refonte.png';
import successImage from '../assets/images/digital3.png';
import './styles/Home.css';



// Styles pour la page Home
const HomeWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background-color: #fff;
  color: #333;
  padding: 0 20px;
  box-sizing: border-box;

  @media (max-width: 768px) {
    padding: 0 15px;
  }

  @media (max-width: 480px) {
    padding: 0 10px;
  }
`;

const HeroSection = styled.section`
  width: 100%;
  height: 80vh;
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${heroImage});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  color: white;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0;
  margin: 0;

  @media (max-width: 768px) {
    height: 60vh;
  }

  @media (max-width: 480px) {
    height: 50vh;
  }
`;


const Title = styled.h1`
  font-size: 4rem;
  font-weight: bold;
  margin: 0.5rem 0;

  @media (max-width: 768px) {
    font-size: 3rem;
  }

  @media (max-width: 480px) {
    font-size: 2rem;
  }
`;

const Subtitle = styled.h2`
  font-size: 2rem;
  margin-bottom: 1.5rem;

  @media (max-width: 768px) {
    font-size: 1.5rem;
  }

  @media (max-width: 480px) {
    font-size: 1.2rem;
  }
`;

const SubtitleSmall = styled.h3`
  font-size: 1.2rem;
  margin: 0.5rem 0;

  @media (max-width: 768px) {
    font-size: 1rem;
  }

  @media (max-width: 480px) {
    font-size: 0.9rem;
  }
`;

const Button = styled.button`
  padding: 15px 30px;
  font-size: 1.2rem;
  color: white;
  background-color: transparent;
  border: 2px solid white;
  border-radius: 26px;
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    background-color: white;
    color: #000;
    border: 2px solid transparent;
    transform: scale(1.1);
  }

  @media (max-width: 768px) {
    padding: 12px 24px;
    font-size: 1rem;
  }

  @media (max-width: 480px) {
    padding: 10px 20px;
    font-size: 0.9rem;
  }
`;

const ContentWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 30px;
  width: 100%;
  max-width: 1200px;
  margin: 50px auto;

  @media (max-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
  }

  @media (max-width: 480px) {
    grid-template-columns: 1fr;
    gap: 15px;
  }
`;

const SectionBase = styled.section`
  padding: 40px 20px;
  border-radius: 12px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  background: linear-gradient(145deg, #ffffff, #f0f0f0);
  text-align: center;
  color: #333;
  box-sizing: border-box;
  transition: transform 0.3s ease, box-shadow 0.3s ease;

  &:hover {
    transform: translateY(-10px);
    box-shadow: 0 16px 24px rgba(0, 0, 0, 0.3);
  }

  @media (max-width: 768px) {
    padding: 30px 15px;
  }

  @media (max-width: 480px) {
    padding: 20px 10px;
  }
`;

const ServiceTitle = styled.h2`
  font-size: 1.8rem;
  margin-bottom: 1rem;
  font-weight: bold;
  color: #000000;

  @media (max-width: 768px) {
    font-size: 1.6rem;
  }

  @media (max-width: 480px) {
    font-size: 1.4rem;
  }
`;

const ServiceText = styled.p`
  font-size: 1.2rem;
  margin: 0 auto;
  line-height: 1.6;
  max-width: 600px;
  color: #666;
`;

const ServiceImage = styled.img`
  width: 80px;
  height: auto;
  margin-bottom: 10px;
`;

const ContactIntro = styled.div`
  margin-top: 50px;
  padding: 0 20px;
  text-align: center;
  color: #333;
`;

const ContactIntroTitle = styled.h2`
  font-size: 2.5rem;
  color: #000000;
  margin-bottom: 20px;
`;

const ContactIntroText = styled.p`
  font-size: 1.2rem;
  margin: 0 auto;
  line-height: 1.6;
  max-width: 800px;
  color: #666;
`;

const SuccessSection = styled.section`
  display: flex;
  align-items: center; 
  justify-content: space-between; 
  padding: 40px 20px;
  background: #ffffff;
  color: #333;
  margin: 0;
  width: 100%;
  max-width: 1200px; 
  box-sizing: border-box;

  @media (max-width: 768px) {
    flex-direction: column; 
    align-items: center; 
    text-align: center; 
    padding: 30px 15px;
  }

  @media (max-width: 480px) {
    padding: 20px 10px;
  }
`;

const SuccessContent = styled.div`
  max-width: 600px;
  text-align: left; 
  flex: 1; 
`;

const SuccessImage = styled.img`
  max-width: 100%; 
  height: auto;
  max-width: 300px;
  margin-left: 20px; 
  transition: transform 1s ease; 

  &:hover {
    transform: rotate(360deg); 
  }

  @media (max-width: 768px) {
    max-width: 100%; 
    margin-left: 0; 
    margin-top: 20px; 
  }
`;

const ContactFormWrapper = styled.div`
  margin-top: 50px;
  padding: 40px 20px;
  border-radius: 12px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  background: linear-gradient(145deg, #ffffff, #f0f0f0);
 
  color: #333;
  font-weight : bold;
  box-sizing: border-box;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  width: 100%;
  max-width: 600px;
  margin-bottom: 100px;

  &:hover {
    transform: translateY(-10px);
    box-shadow: 0 16px 24px rgba(0, 0, 0, 0.3);
  }

  @media (max-width: 768px) {
    padding: 30px 15px;
  }

  @media (max-width: 480px) {
    padding: 20px 10px;
  }
`;

const ContactForm = styled.form`
  display: flex;
  flex-direction: column;
`;

const Input = styled.input`
  padding: 15px;
  font-size: 1rem;
  margin-top: 10px;
  margin-bottom: 20px;
  border: 2px solid #ddd;
  border-radius: 8px;
  outline: none;
  box-sizing: border-box;

  &:focus {
    border-color: #6f0b0b;
  }
`;

const Textarea = styled.textarea`
  padding: 15px;
  font-size: 1rem;
  margin-top: 10px;
  margin-bottom: 20px;
  border: 2px solid #ddd;
  border-radius: 8px;
  outline: none;
  box-sizing: border-box;

  &:focus {
    border-color: #6f0b0b;
  }
`;

const SubmitButton = styled.button`
  padding: 15px 30px;
  font-size: 1.2rem;
  color: #000000;
  background-color: #ffffff;
  border: 2px solid #000000;
  border-radius: 26px;
  cursor: pointer;
  transition: all 0.3s ease;
  display: block;
  margin: 0 auto;

  &:hover {
    background-color: black; /* Fond blanc au survol */
    color: white; /* Texte noir au survol */
    border: 2px solid white; /* Bordure noire au survol */
    transform: scale(1.1); /* Agrandissement au survol */
  }

  @media (max-width: 768px) {
    padding: 15px 30px; /* Ajuste le padding pour les écrans plus petits */
    font-size: 0.8rem; /* Ajuste la taille de la police pour les écrans plus petits */
  }

  @media (max-width: 480px) {
    padding: 15px 30px; /* Réduit davantage le padding pour les très petits écrans */
    font-size: 0.7rem; /* Réduit davantage la taille de la police pour les très petits écrans */
  }
`;

const CaptchaWrapper = styled.div`
  margin-bottom: 20px;
  font-size: 1rem;
  color: #333;
`;

const Home = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
    captchaAnswer: '',
  });

  const [captchaError, setCaptchaError] = useState('');
  const [captchaQuestion, setCaptchaQuestion] = useState('');
  const [correctCaptchaAnswer, setCorrectCaptchaAnswer] = useState(null);
  const contactFormRef = useRef(null);

  // Générer une question CAPTCHA simple
  const generateCaptcha = () => {
    const num1 = Math.floor(Math.random() * 10);
    const num2 = Math.floor(Math.random() * 10);
    setCaptchaQuestion(`Combien font ${num1} + ${num2} ?`);
    setCorrectCaptchaAnswer(num1 + num2);
  };

  // Générer la question CAPTCHA au chargement du composant
  useEffect(() => {
    generateCaptcha();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    
    // Vérifier la réponse CAPTCHA
    if (parseInt(formData.captchaAnswer) !== correctCaptchaAnswer) {
      setCaptchaError('CAPTCHA incorrect. Veuillez réessayer.');
      generateCaptcha(); // Générer un nouveau CAPTCHA
      setFormData(prevData => ({
        ...prevData,
        captchaAnswer: '', // Réinitialiser le champ CAPTCHA
      }));
      return;
    }
    
    setCaptchaError('');
    
    // Préparez les données du formulaire pour l'URL mailto
    const mailtoLink = `mailto:tonyboy@testtest007.fr?subject=Message de ${encodeURIComponent(formData.name)}&body=${encodeURIComponent(formData.message)}%0A%0AEmail: ${encodeURIComponent(formData.email)}`;
    
    // Redirige l'utilisateur vers l'URL mailto
    window.location.href = mailtoLink;
    
    console.log('Formulaire soumis avec succès:', formData);
  };

  const scrollToContactForm = () => {
    if (contactFormRef.current) {
      contactFormRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  };

  return (
    <HomeWrapper>
      <HeroSection>
        <Title>Votre Partenaire Digital</Title>
        <Subtitle>Intégration Web & Identité Visuelle</Subtitle>
        <Button onClick={scrollToContactForm}>Contact</Button>
      </HeroSection>

      <SuccessSection>
        <SuccessContent>
          <ServiceTitle>Votre réussite digitale.</ServiceTitle>
          <SubtitleSmall>Votre vision, mon expertise.</SubtitleSmall>
          <ServiceText>
          Je suis prêt à vous aider à réussir dans le monde numérique. Que vous ayez besoin d'une <b>refonte complète</b> pour moderniser votre présence en ligne, de la <b>création d'un site web</b> de A à Z, de la conception d'une <b>identité visuelle</b> unique, ou de services de <b>SEO</b> pour améliorer votre visibilité, j'ai les compétences nécessaires pour vous propulser vers le succès. Chaque projet que je réalise est conçu sur mesure pour répondre à vos objectifs spécifiques et pour garantir des résultats tangibles.
          </ServiceText>
        </SuccessContent>
        <SuccessImage src={successImage} alt="Illustration de réussite digitale" />
      </SuccessSection>

      <ContentWrapper>
        <SectionBase>
          <ServiceTitle>Refonte</ServiceTitle>
          <ServiceImage src={refonteImage} alt="Refonte icone" />
          <ServiceText>
            Refonte de sites web existants pour améliorer leur performance, leur design et leur fonctionnalité.
          </ServiceText>
        </SectionBase>

        <SectionBase>
          <ServiceTitle>Identité Visuelle</ServiceTitle>
          <ServiceImage src={identityImage} alt="Identité Visuelle" />
          <ServiceText>
            Conception de logos et de chartes graphiques qui reflètent votre marque et attirent vos clients.
          </ServiceText>
        </SectionBase>

        <SectionBase>
          <ServiceTitle>Webdesign</ServiceTitle>
          <ServiceImage src={webdesignImage} alt="Webdesign" />
          <ServiceText>
            Création de sites web modernes et fonctionnels qui captent l'attention et engagent vos visiteurs.
          </ServiceText>
        </SectionBase>

        <SectionBase>
          <ServiceTitle>SEO</ServiceTitle>
          <ServiceImage src={seoImage} alt="SEO" />
          <ServiceText>
            Optimisation pour les moteurs de recherche afin d'améliorer la visibilité de votre site web et attirer plus de visiteurs.
          </ServiceText>
        </SectionBase>

        <SectionBase>
          <ServiceTitle>Print</ServiceTitle>
          <ServiceImage src={printImage} alt="Print" />
          <ServiceText>
            Création de supports imprimés tels que brochures, cartes de visite et affiches pour promouvoir votre entreprise.
          </ServiceText>
        </SectionBase>

        <SectionBase>
          <ServiceTitle>Logo Design</ServiceTitle>
          <ServiceImage src={logoImage} alt="Logo Design" />
          <ServiceText>
            Création de logos uniques et mémorables qui représentent l'essence de votre entreprise.
          </ServiceText>
        </SectionBase>
      </ContentWrapper>

      <ContactIntro>
        <ContactIntroTitle>Contact</ContactIntroTitle>
        <ContactIntroText>
          Pour toute demande d'information, n'hésitez pas à me contacter via le formulaire ci-dessous.
        </ContactIntroText>
      </ContactIntro>

      <ContactFormWrapper ref={contactFormRef}>
        <ContactForm onSubmit={handleSubmit}>
        <label htmlFor="name">Votre nom :</label>
<Input
  type="text"
  id="name"
  name="name"
  placeholder=""
  value={formData.name}
  onChange={handleChange}
  required
/>

<label htmlFor="email">Votre email :</label>
<Input
  type="email"
  id="email"
  name="email"
  placeholder=""
  value={formData.email}
  onChange={handleChange}
  required
/>

<label htmlFor="message">Votre message :</label>
<Textarea
  id="message"
  name="message"
  placeholder=""
  rows="5"
  value={formData.message}
  onChange={handleChange}
  required
/>
<CaptchaWrapper>
  <p></p>
  <label htmlFor="captchaAnswer">Vérification de sécurité :     </label>
  <Input
    type="text"
    id="captchaAnswer"
    name="captchaAnswer"
    placeholder={captchaQuestion}
    value={formData.captchaAnswer}
    onChange={handleChange}
    required
  />
            {captchaError && <p style={{ color: 'red' }}>{captchaError}</p>}
          </CaptchaWrapper>
          <SubmitButton type="submit">Envoyer</SubmitButton>
        </ContactForm>
      </ContactFormWrapper>
    </HomeWrapper>
  );
};

export default Home;

