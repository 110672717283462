import React from 'react';
import './styles/About.css';
import yourImage from '../assets/images/info.png'; 

const About = () => {
  return (
    <div className="AboutWrapper">
      <div className="AboutContainer">
        <img className="Image" src={yourImage} alt="Icone info" />
        <h1 className="Title">Ma formation</h1>
        <h2 className="Subtitle">Intégrateur web</h2>
        
        <section className="BioSection">
          <p className="BioText">
            Je m'appelle Marc et je suis développeur web, avec une spécialisation en <b>React et Javascript</b>. Mon parcours professionnel a commencé par une formation d'intégrateur web chez OpenClassrooms, où j'ai acquis une solide compréhension des fondamentaux du développement frontend.<br/><br/>
            <b>En HTML</b>, j'ai appris à structurer des pages web en utilisant des éléments sémantiques pour une meilleure <b>accessibilité et SEO</b>. J'ai compris l'importance des attributs, des balises, et de la hiérarchie des contenus pour organiser les informations de manière claire et cohérente.<br/><br/>
            <b>En CSS</b>, j'ai appris à styliser les pages web en utilisant des sélecteurs, des propriétés et des valeurs pour personnaliser l'apparence des éléments. J'ai maîtrisé les concepts de mise en page comme le modèle de boîte, les flexbox, et les grilles pour créer des designs réactifs et adaptatifs. J'ai aussi appris à <b>utiliser les préprocesseurs comme SASS</b> pour écrire du CSS de manière plus efficace et maintenable.<br/><br/>
            <b>En REACT</b>, j'ai appris à créer des interfaces utilisateur dynamiques avec des hooks, à utiliser <b>React Router</b> pour la navigation, et à gérer les données avec props et context. J'ai aussi optimisé le développement avec des outils comme Webpack et Babel.<br/><br/>
            <b>En JavaScript</b>, j'ai appris à <b>manipuler le DOM</b>, gérer les événements, et <b>travailler avec les API</b>. J'ai maîtrisé les concepts de base, la gestion du code asynchrone avec les promesses et async/await, ainsi que la manipulation des objets et des tableaux, tout en adoptant des bonnes pratiques pour le code. <br/><br/>
            Cette formation a non seulement affiné mes compétences techniques, mais a également renforcé ma capacité à transformer des maquettes en expériences utilisateur fluides et réactives.
          </p>
        </section>

        <section className="SkillsSection">
          <h2 className="SkillsTitle">Compétences</h2>
          <div className="SkillsList">
            <div className="SkillItem">
              <div className="SkillName">HTML5 & CSS3</div>
              <div className="SkillBar">
                <div className="SkillProgress" style={{ width: '90%', backgroundColor: '#e34c26' }}></div>
              </div>
            </div>
            <div className="SkillItem">
              <div className="SkillName">JavaScript</div>
              <div className="SkillBar">
                <div className="SkillProgress" style={{ width: '62%', backgroundColor: '#f0db4f' }}></div>
              </div>
            </div>
            <div className="SkillItem">
              <div className="SkillName">React</div>
              <div className="SkillBar">
                <div className="SkillProgress" style={{ width: '80%', backgroundColor: '#61dafb' }}></div>
              </div>
            </div>
            <div className="SkillItem">
              <div className="SkillName">Node.js</div>
              <div className="SkillBar">
                <div className="SkillProgress" style={{ width: '75%', backgroundColor: '#68a063' }}></div>
              </div>
            </div>
            <div className="SkillItem">
              <div className="SkillName">Git & GitHub</div>
              <div className="SkillBar">
                <div className="SkillProgress" style={{ width: '80%', backgroundColor: '#f05032' }}></div>
              </div>
            </div>
            <div className="SkillItem">
              <div className="SkillName">VScode</div>
              <div className="SkillBar">
                <div className="SkillProgress" style={{ width: '90%', backgroundColor: '#0065fc' }}></div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default About;
