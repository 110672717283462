
import React from 'react';
import { Link } from 'react-router-dom';
import './Header.css';
import logo from '../assets/images/logo.png'; 

const Header = () => (
  <header>
    <div className="logo">
    
        <img src={logo} alt="Logo du site Portfolio" />
  
    </div>
    <nav>
      <ul>
        <li><Link to="/">Accueil</Link></li>
        <li><Link to="/projects">Projets</Link></li>
        <li><Link to="/about">À propos</Link></li>
       
       
      </ul>
    </nav>
  </header>
);

export default Header;

