import React from 'react';
import './styles/Projects.css';
import projet1Image from '../assets/images/booki.png';
import projet2Image from '../assets/images/myfood.png';
import projet3Image from '../assets/images/carducci.png';
import projet5Image from '../assets/images/Printit.png';
import githubLogo from '../assets/images/Github2.png'; 

const Projects = () => {
  const projectData = [
    {
      title: "Booki",
      description: "Utilisation du code HTML et CSS pour l'intégration de l'interface du site. Intégration des maquettes du site selon les spécifications et contraintes techniques fournies.",
      imageUrl: projet1Image,
      link: "https://marc-74.github.io/Booki/",
      codeLink: "https://github.com/Marc-74/Booki", 
      altText: "Capture d'écran du projet Booki",
      competences: "HTML, CSS, Responsive design"
    },
    {
      title: "Nina Carducci",
      description: "Optimisation des images, du code et de la structure pour une amélioration optimale du chargement et de l'accessibilité.",
      imageUrl: projet3Image,
      link: "https://marc-74.github.io/Nina-Carducci/",
      codeLink: "https://github.com/Marc-74/Nina-Carducci", 
      altText: "Capture d'écran du projet Nina Carducci",
      competences: "Optimisation, Accessibilité"
    },
    {
      title: "Print it",
      description: "Intégration d'un carrousel d'images dynamique avec bullets points sur le site web statique de l'imprimerie Print-it avec JavaScript.",
      imageUrl: projet5Image,
      link: "https://marc-74.github.io/Print-it/Print-it-JS-main/index.html",
      codeLink: "https://github.com/Marc-74/Print-it", 
      altText: "Capture d'écran du projet Print it",
      competences: "JavaScript, Carrousel d'images"
    },
    {
      title: "Oh my food",
      description: "Développement d'un site web mobile first répertoriant les menus de restaurants gastronomiques avec des systèmes de réservation et de précommande de repas.",
      imageUrl: projet2Image,
      link: "https://marc-74.github.io/Oh-My-Food/html/index.html",
      codeLink: "https://github.com/Marc-74/Oh-My-Food", 
      altText: "Capture d'écran du projet Oh my food",
      competences: "Développement mobile first, HTML, CSS, SASS"
    },
  ];

  return (
    <div className="ProjectsWrapper">
      <h1 className="Title">Mes Projets</h1>
      <div className="ProjectsContainer">
        {projectData.map((project, index) => (
          <div className="ProjectCard" key={index}>
            <img className="ProjectImage" src={project.imageUrl} alt={project.altText} />
            <div className="ProjectContent">
              <h2 className="ProjectTitle">{project.title}</h2>
              <p className="ProjectDescription">{project.description}</p>
              <p className="Competences">Compétences: {project.competences}</p>
              <div className="ProjectLinkContainer">
                <a className="ProjectLink" href={project.link} target="_blank" rel="noopener noreferrer">
                  Voir en ligne
                </a>
                <a className="GitHubLink" href={project.codeLink} target="_blank" rel="noopener noreferrer">
                  <img src={githubLogo} alt="GitHub logo" />
                </a>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Projects;
