import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/Header';
import Home from './pages/Home';
import About from './pages/About';
import Projects from './pages/Projects';
import Footer from './pages/Footer'; 

const App = () => (
  <Router>
    <Header />
    <main style={{ minHeight: 'calc(100vh - 80px)' }}> 
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/projects" element={<Projects />} />
  
      </Routes>
    </main>
    <Footer /> 
  </Router>
);

export default App;
