import React from 'react';
import './styles/Footer.css'; 
import githubIcon from '../assets/images/github.png'; 
import linkedinIcon from '../assets/images/linkedin.png'; 

const Footer = () => {
  return (
    <footer className="footer-wrapper">
      <p className="footer-text">Marc Ponsbek - ©2024</p>
      <div className="footer-links">
        <a 
          href="https://github.com/Marc-74?tab=repositories" 
          target="_blank" 
          rel="noopener noreferrer"
          aria-label="GitHub"
        >
          <img src={githubIcon} alt="GitHub Icon" className="github-icon" />
        </a>
        <a 
          href="https://www.linkedin.com/in/marc-ponsbek" 
          target="_blank" 
          rel="noopener noreferrer"
          aria-label="LinkedIn"
        >
          <img src={linkedinIcon} alt="LinkedIn Icon" className="linkedin-icon" />
        </a>
        <a 
          href="mailto:tonyboy@testtest007.fr" 
          target="_blank" 
          rel="noopener noreferrer"
          aria-label="Contact"
          className="contact-link"
        >
          Contact
        </a>
      </div>
    </footer>
  );
};

export default Footer;




